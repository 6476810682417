.haasAcademyOverlay {
  /* background: -webkit-linear-gradient(rgba(53, 0, 66, 0.75), rgba(0, 14, 66, 0.75)), url("/media/illustrations/nyc-empirestate.jpg");
  background: linear-gradient(rgba(53, 0, 66, 0.75), rgba(0, 14, 66, 0.75)), url("/media/illustrations/nyc-empirestate.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  background: white;
  border-top: 1px solid #e2e2e2;
}

.caasQuoteOverlay {
  background: -webkit-linear-gradient(rgba(53, 0, 66, 0.75), rgba(0, 14, 66, 0.75)), url("../../assets/media/illustrations/tools.jpg");
  background: linear-gradient(rgba(53, 0, 66, 0.75), rgba(0, 14, 66, 0.75)), url("../../assets/media/illustrations/tools.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}