#AssetsManagement .ui.statistic {
    display: block;
}

#AssetsManagement .metadata-field-filter {
    margin-right: 10px;
    margin-bottom: 10px;
}


#AssetsManagement .sub {
    font-size: 0.8em;
}

#AssetsManagement .InvestorDashboard .statistic {
    margin-bottom: 25px;
}

.MetadataMapComponent{
    border: 1px solid #ccc;
}

#AssetsManagement .aggregate-filters {
    margin-bottom: 30px;
}

#AssetsManagement .nav-header-item {
    padding-left: 0.89em;
    padding-right: 0.89em;
}

#ps-alerts-page .MuiDataGrid-columnHeaders {
    display: none;
}

.react-datepicker-popper {
    z-index: 3;
}


.ps-widget-metric {
    margin-bottom: 15px;
    font-size: 1.5em;
}

.ps-widget-metric strong {
    color: #6435C9
}

#AssetsManagement .multi-select-checkbox label{
    word-wrap: break-word;
    white-space: pre-wrap;
}

#AssetsManagement .react-datepicker__input-container input {
    height: 37px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 9px;
}