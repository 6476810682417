/* OfferBanner.css */
.offer-banner {
    background-color: #ff9800; /* Bright orange background */
    color: #fff;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure the banner stays on top */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.offer-message {
    font-size: 18px;
    font-weight: bold;
    margin-right: 15px;
}

.offer-action {
    background-color: #fff;
    color: #ff9800;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}

.offer-action:hover {
    background-color: #f5f5f5;
}
