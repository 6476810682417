
.date-range {
    width: 340px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.date-range.vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 0.8rem;
    width: 100%;
    row-gap: 0.2rem;
}

.date-range input {
    border: 1px solid #ccc;
    padding: 4px;
}

.date-range-label {
    font-size: 0.8rem;
    color: #666;
    margin-right: 0.5rem;
}