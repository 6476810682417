body {
    background-color: #FAFCFE;
}

#ps-platform .code-block{
    background-color: #666666;
    color: white;
    padding-left: 20px;
}

#ps-platform {
    background-color: #FAFCFE;
}
#ps-platform .ps-top {
    height: 50px;
}
#ps-platform .ps-top .right {
    float: right;
    padding-top: 8px;
}
#ps-platform .ps-top .left {
    float: left;
}

#ps-platform .ps-top .button{
    background: none;
    border: none;
    padding-top: 8px;
    padding-right: 15px;
}

#ps-platform .ps-header-container {
    background-color: white;
    padding: 2rem 0;
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    margin-bottom: 2rem;
}

#ps-platform .header {
    font-size: 2em;
}

#ps-platform .ps-top {
    background-color: white;
    padding: 0 3em;
    border-bottom: 1px solid #eee;
}

#ps-platform .ps-top .logo{
    height: 3em;
}

#ps-platform .button.primary {
    background-color: #6435C9;
}

#ps-platform .not-applicable-cell {
    justify-content: flex-end;
}

#ps-platform .lineItemGlossaryRow {
    background-color: white;
    transition: background-color 0.3s;
}

#ps-platform .lineItemGlossaryRow:nth-child(odd) {
    background-color: #f7f7f7;
}

#ps-platform .lineItemGlossaryRow:hover {
    background-color: #d0e3ff;
}

#ps-platform .card .recommended {
    background-color: #6435C9;
    color: white;
    padding: 0.4em 0.8em;
    width: 50%;
    border-radius: 0.28571429rem 0 0.28571429rem 0  !important;
}

#ps-platform .fileUploader .text .progress .bar {
    background: #6435C9;
}

#ps-platform .card .extra .link {
    color: #6435C9;
    float: right;
}

#ps-platform .card .description {
    padding: 1.3em;
}

#ps-platform .fileUploader {
    background: none;
    padding: 1.5em;
    border: 1px dashed #666;
    border-radius: 0.28571429rem 0 0.28571429rem 0  !important;
    min-height: 162px;
}

#ps-platform .fileUploader .text {
    font-size: 16px;
    padding: 6px 0px;
}

#ps-platform .fileUploader .text strong {
    display: block;
    padding-bottom: 6px;
}

#ps-platform .fileUploader .icon {
    font-size: 3rem;
    position: relative;
    left: -6px;
}

#ps-platform .file-uploader-controls-container {
display: flex; margin-top: 10px; justify-content: space-between; align-items: center;
}

#ps-platform .ui.secondary.pointing.menu {
    border-bottom: 0px
}

#ps-platform .toast-center {
    top: 10%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, 0px);
    z-index: 9999;
}

.form-item {
    width: 100%;
    border-color:#ccc;
    margin-bottom: 20px;
}

textarea.form-item {
    border-radius: 0.28571429rem;
    padding: 0.67857143em 1em;
    color: #ccc;
}

textarea.form-item:focus {
    color: #333;
}

#ps-platform .menu .header {
    font-size: 0.78571429em;
}

#ps-platform .cell-error-orange-border {

}


#ps-platform .ui.error.message .header {
    font-size: 1.2em;
}

#ps-platform .editable-widget{
    position: relative;
}
#ps-platform .widget-actions {
    display: none;
    position: absolute;
    z-index: 2000;
    left: 0;
    top: -10px;
}
#ps-platform .widget-action {
    padding: 2px;
    font-size: 10px;
}
#ps-platform .editable-widget:hover>.widget-actions {
    display: inline-block;
}

#ps-platform .widget {
  padding-bottom: 5px;
    padding-top: 5px;
}

#ps-platform .psFileUploader {
    padding: 1.5em;
    border: 1px dashed #666;
    border-radius: 0.28571429rem 0 0.28571429rem 0  !important;
}

#hubspot-messages-iframe-container {
    display: none;
}

._PuckLayout_1g88c_31 {
    position: relative !important;
}

.widget-columns {
    display: flex;
    gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    flex-direction: column;
    min-height: 0; /* NEW */
    min-width: 0; /* NEW; needed for Firefox */
}

@media (min-width: 768px) {
    .widget-columns {
        display: grid;
    }
}

.widget-wrapper-section:not(.widget-wrapper-section .widget-wrapper-section) {
    padding-left: 16px;
    padding-right: 16px;
}

@media (min-width: 768px) {
    #ps-platform .widget-wrapper-section:not(.widget-wrapper-section .widget-wrapper-section) {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.widget-wrapper-section:not(.widget-wrapper-section .widget-wrapper-section) .widget-wrapper-section-inner {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.widget-heading-wrapper {
    display: block;
    color: var(--puck-color-black);
    font-weight: 700;
    margin: 0;
}

.widget-heading-wrapper b {
    font-weight: 700;
}

.widget-heading-wrapper--xxxxl {
    font-size: var(--puck-font-size-xxxxl);
    letter-spacing: 0.08ch;
    font-weight: 800;
}

.widget-heading-wrapper--xxxl {
    font-size: var(--puck-font-size-xxxl);
}

.widget-heading-wrapper--xxl {
    font-size: var(--puck-font-size-xxl);
}

.widget-heading-wrapper--xl {
    font-size: var(--puck-font-size-xl);
}

.widget-heading-wrapper--l {
    font-size: var(--puck-font-size-l);
}

.widget-heading-wrapper--m {
    font-size: var(--puck-font-size-m);
}

.widget-heading-wrapper--s {
    font-size: var(--puck-font-size-s);
}

.widget-heading-wrapper--xs {
    font-size: var(--puck-font-size-xs);
}