.mobileWrapper {
  min-height: 100vh;
  min-width: 100%;
}

.coverTopNav {
  background-color: white;
  /* background-color: #1b1c1d; */
}

.pusher.overflowingSidebar {
  min-height: 100vh;
}

.ui.sidebar.overflowingSidebar {
  overflow: visible !important;
}

.navLogo {
  height: 4em;
  vertical-align: bottom;
}

.logoNavText {
  font-weight: 900;
  padding-right: 2em;
}

.ui.menu.boxMenu {
  border-radius: 0;
}

.ui.menu.invisibleMenu {
  box-shadow: none;
  border: none;
}

.negSideMargins1 {
  margin-left: -1em !important;
  margin-right: -1em !important;
}