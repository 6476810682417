
/* SignUpSplit.css */

#esg2 .splitContainer {
    display: flex;
    min-height: 100vh;
    background-color: #f7f9fc;
    overflow: hidden; /* Prevent scrollbars if any content overflows slightly */
}

/* Left side: Attractive gradient, branding, marketing info */
#esg2 .splitLeft {
    flex: 1;
    position: relative;
    background: linear-gradient(135deg, #480971 0%, #f4e8f6 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    color: #fff;
}

/* Optional overlay if you want to dim or lighten the background further
   for improved text contrast. Uncomment if needed.

.splitLeft::before {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none;
}
*/

#esg2 .branding {
    position: relative; /* so it layers above the optional overlay */
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}

#esg2 .branding-variant2 {
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4); /* a translucent overlay for readability */
    padding: 2rem;
    border-radius: 8px;
}

#esg2 .logo {
    display: inline-block;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 1px;
}

#esg2 .branding h1 {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    line-height: 1.2;
}

#esg2 .branding p {
    font-size: 1.05rem;
    line-height: 1.6;
    margin: 0;
}

/* Right side: Sign-up form */
#esg2 .splitRight {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

#esg2 .formContainer {
    width: 100%;
    max-width: 450px;
    background-color: #fff;
    padding: 2rem 2.5rem;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.5s ease; /* Subtle animation on load */
}

#esg2 .formHeader {
    text-align: center;
    margin-bottom: 1.5rem;
}

#esg2 .formHeader h2 {
    margin: 0;
    font-size: 1.8rem;
    color: #333;
    font-weight: 700;
}

#esg2 .formHeader p {
    font-size: 1rem;
    color: #666;
    margin-top: 0.5rem;
}

/* Spacing for each form field */
#esg2 .field {
    margin-top: 1.2rem;
}

/* Button styles */
#esg2 .submitButton {
    margin-top: 1.8rem !important;
    font-weight: 400;
    /*text-transform: none;*/
    background-color: #480971 !important; /* Force your gradient color for brand consistency */
}

/* Button hover effect */
#esg2 .submitButton:hover {
    background-color: #350657 !important;
}

/* Keyframes for fade-in animation */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive breakpoint for smaller screens */
@media screen and (max-width: 768px) {
    #esg2 .splitContainer {
        flex-direction: column;
    }

    #esg2 .splitLeft, .splitRight {
        flex: none;
        width: 100%;
        min-height: 50vh;
    }

    /* Make the gradient more centered for mobile */
    #esg2 .splitLeft {
        padding: 2.5rem 2rem;
        background-position: center;
    }
}