.welcome-text p {
  font-size: 16px;
  font-weight: 500;
}

.ui.header.upgrade-description {
  text-align: center;
  color: #6b2ea8;
  font-size: 1.25em;
  font-weight: 400;
  margin-bottom: 24px;
}

.segment.upgrade-segment.inverted {
  background:  #5d3388; /* #6b2ea8 */
  margin-top: 0em !important;
}

.segment.upgrade-segment {
  border-radius: 12px;
  box-shadow: -5px 5px 15px rgb(0 0 0 / 8%);
  /* max-width: 400px; */
  /* margin-top: 2em !important; */
}

.upgrade-features {
  padding: 0.5em 1em;
  margin-bottom: 16px;
}

.upgrade-features-text {
  margin-top: 4px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  display: none !important;
}

.upgrade-divider-text {
  margin-bottom: 6px;
  font-size: 0.8em;
  font-weight: 400;
  color: #9b51e0;
}

.upgrade-divider-contents {
  position: absolute;
  margin: 0;
  padding: 0;
  text-align: center;
  top: 35%;
  left: 0;
  width: 100%;
}

.upgrade-divider {
  position: relative;
  height: 200px;
  width: 75px;
  font-size: 50px;
  border-radius: 50%;
  display: inline-block;
  /* border: 3px solid #9b51e0; */
  /* box-shadow: 0px 0px 8px #f7c9ff; */
  background: transparent;
}

.upgrade-circle-text {
  margin-bottom: 6px;
  font-size: 0.8em;
}

.upgrade-circle-contents {
  position: absolute;
  margin: 0;
  padding: 0;
  text-align: center;
  top: 35%;
  left: 0;
  width: 100%;
}

.upgrade-circle.inverted {
  border: 3px solid #bd9aff;
  box-shadow: 0px 0px 8px #ead8fd;
}

.upgrade-circle {
  position: relative;
  height: 200px;
  width: 200px;
  font-size: 50px;
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #9b51e0;
  box-shadow: 0px 0px 8px #f7c9ff;
  background: transparent;
}

.ui.ui.menu .item.disabled.unlockable-tab {
  color: #9b51e0;
  cursor: pointer;
  pointer-events: auto;
}

.float-right {
  float: right;
}

.ui.table > tbody > tr > td.tabledata-highlight-cell {
  color: #ff9800;
  font-weight: 900;
}

.ui.table > thead > tr > th.tabledata-highlight-cell {
  color: #9b51e0;
  font-weight: 900;
}

.dashcard-calc-value.red {
  color: #f44336;
}
.dashcard-calc-value.purple {
  color: #9b51e0;
}

.ui.message.dashtab-message {
  box-shadow: 8px 8px 20px 0px #392a5b40;
  margin-bottom: 30px;
}

.ui.segment.mgmt-dashtab {
  margin-top: 0;
}

.ui.grid .row + .ui.divider.dashcard-calc-divider {
  margin: 0.25rem 0.25rem;
}

.ui.grid.dashcard-calc-grid > .row {
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
}

.dashcard-calc-value {
  font-weight: 600;
  color: #434343;
  font-size: 1em;
}

.dashcard-calc-desc {
  font-weight: 500;
  color: #797979;
  font-size: 1em;
}

.dashcard-calc-subtitle {
  font-weight: 900;
  color: #797979;
  text-transform: uppercase;
  font-size: 0.75em;
}

.ui.segment.dashcard-segment {
  padding: 0 30px 30px 30px;
}

.dashcard-metric-subtitle {
  font-weight: 600;
  color: #979797;
}

.ui.horizontal.statistic.dashcard-highlight-metric {
  align-items: baseline;
}

.ui.statistic.dashcard-highlight-metric .value {
  font-weight: 600;
  font-size: 42px;
}

.ui.segments.dashcard {
  border-radius: 16px;
  box-shadow: 8px 8px 20px 0px #392a5b40;
  border: 1px solid #f9f9f9;
  margin-bottom: 30px;
  background: white;
}

.ui.segment.dashcard-header-segment {
  padding: 2.25em;
}

.ui.segment.dashcard-header-segment.compact {
  padding: 13px;
}

.ui.header.dashcard-header {
  margin-bottom: 0;
  font-size: 17.5px;
  font-weight: 500;
}

.ui.header.dashcard-header > .icon {
  float: right;
  font-size: 1.5em;
  margin: 8px 0 0 0;
}

.ui.avatar.image.square {
  border-radius: 0;
}

.yc-text {
  font-size: 18px;
  font-weight: 600;
  color: #999999;
  border-top: 1px solid #e1e1e1;
  width: 50%;
  margin: auto;
  margin-top: 52px;
  padding-top: 36px;
}

.ui.image.yc-logo {
  max-height: 42px;
  display: inline-block;
}

.termSheetItemsDetail p {
  margin-bottom: 0.75em;
  font-weight: 900;
}

/* Background Styles */

.coloredBGCircle {
  background: radial-gradient(circle at center,  #9b51e00d 0%,#edeaef 47%,#ff000000 47%,#23bc2b00 47%,#23bc2b00 48%);
}

/* Typography Style */

.ui.header.tightHeader {
  margin-bottom: 0rem;
}

/* Remove default lines after menu items in navbar */
.ui.menu.boxMenu .item:before {
  display: none;
}

/* Style the video: 100% width and height to cover the entire window */
.heroVideoContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.heroVideo {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

video[poster] {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Original General Styles */

.slightLeftMargin {
  margin-left: 6px !important;
}

.orangeLink {
  color: #f2711c;
}

.orangeLink:hover {
  color: #fd9e25;
}

.ui.button.round {
  border-radius: 3em;
}

html {
  background: #FFF;
  /* background: url(images/bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

body {
  background: none;
}

.ui.container.bottomBumper {
  padding-bottom: 5em;
}

.ui.circular.label.buttonCircleNotification {
  font-size: 0.4em;
  position: absolute;
  top: 0;
  right: 0;
  margin: 4px 4px 0 0;
}

ol.ui.list li:before {
  color: rgba(162, 160, 160, 0.87);
  font-weight: bold;
}

p.hugeText {
  font-size: 1.2em;
}

i.inverted.circular.icon.heroIcon {
  background: none !important;
  color: #fff !important;
  border: 1px solid white;
  font-size: 2em;
  margin: 0;
}

.transactionDetailItem {
  font-size: 0.75em;
  font-weight: normal;
  margin-left: 6px;
  vertical-align: bottom;
}

.transactionDateItem {
  font-size: 0.75em;
  font-weight: normal;
  vertical-align: bottom;
}

.transactionDetailTags {
  margin-left: 6px;
}

.transactionDetailTags .ui.label {
  padding: 0.45em;
}

.ui.vertical.segment.white {
  background: white;
}

/* New Styles */

/* General */

.primary-bg {
  background-color: #FFF;
}

.secondary-bg {
  background-color: #EEEEEE;
}

/* Form */

.ui.dropdown:not(.button) > .default.text {
  color: #555555
}

.ui.form.outlineForm input {
  border: none;
  border-bottom: 2px solid lightgray;
  border-radius: 0;
  padding-left: .5rem;
}

.ui.form input::placeholder {
  color: gray;
  opacity: 1;
}

.ui.form textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray;
  opacity: 1; /* Firefox */
}

.ui.form textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: gray;
}

.ui.form textarea::-ms-input-placeholder { /* Microsoft Edge */
  color: gray;
}

/* Button */

.ui.button.raised {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* .ui.inverted.form label {
  color: purple;
  opacity: 1;
} */

/* Hero Page Styles */

.heroSection {
  padding: 8em 0;
}

h2.ui.header.hero-smallheader {
  margin-bottom: 36px;
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  letter-spacing: 3.36px;
  text-transform: uppercase;
}

h1.ui.header.hero-title {
  font-size: 55px;
  font-weight: 900;
  line-height: 1.1;
}

h1.ui.header.hero-title .hero-subheader {
  font-size: 42px;
  font-weight: 900;
  line-height: 1.2;
}

p.hero-subtitle {
  color: #7F848D;
  font-size: 22px;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 50px;
}

h2.ui.header.hero-header {
  margin-bottom: 28px;
  font-size: 42px;
  line-height: 1.1;
  font-weight: 900;
}

p.hero-subheader {
  color: #7F848D;
  font-size: 30px;
  line-height: 1.3;
  font-weight: 500;
}

.ui.header.hero-statistic {
  font-size: 60px;
}

.ui.header.hero-statSubtitle {
  font-size: 32px;
  color: #7F848D;
}

p.hero-quote {
  font-size: 24px;
  color: #7F848D;
}

.hero-quote-bg {
  background: url('./assets/media/graphics/AssetScalingLines-Both.png') no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.hero-feature-bg {
  background: url('./assets/media/graphics/Perl-Feature-Graphic-1.png') no-repeat top right;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

p.cta-subtitle {
  color: #7F848D;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
}

p.cta-footerText {
  margin-top: 24px;
  color: #7F848D;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
}

.ui.grid > .row > .column.feature-bg-column {
  display: none;
}

.footer-menu {
  text-align: initial;
}

.scenario-input-btn-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

@media screen and (min-width:768px) and (max-width:1381px) {
  .scenario-input-btn {
    margin: 2px !important;
  }
  .scenario-input-btn-container {
    flex-direction: column !important;
  }
}

/* If the screen size is 600px wide or less */
@media screen and (max-width: 991px) {

  .yc-text {
    width: 90%;
    margin: auto;
    margin-top: 42px;
    padding-top: 24px;
    padding-bottom: 32px;
  }

  .ui.vertical.menu {
    display: inline-block;
  }

  .navLogo {
    height: 2em;
  }

  .ui.grid > .row > .column.feature-bg-column {
    display: inline-block;
  }

  .heroSection {
    padding: 4em 0;
  }

  h1.ui.header.hero-title {
    font-size: 46px;
    font-weight: 900;
    line-height: 1.1;
  }

  h1.ui.header.hero-title .hero-subheader {
    font-size: 18px;
    font-weight: 900;
    line-height: 1.2;
  }

  p.hero-subtitle {
    color: #7F848D;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 50px;
  }

  h2.ui.header.hero-header {
    margin-bottom: 28px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 900;
  }

  p.hero-subheader {
    color: #7F848D;
    font-size: 20px;
    line-height: 1.3;
    font-weight: 500;
  }

  .ui.header.hero-statistic {
    font-size: 48px;
  }

  .ui.header.hero-statSubtitle {
    font-size: 20px;
    color: #7F848D;
  }

  p.hero-quote {
    font-size: 18px;
    color: #7F848D;
  }

  .hero-feature-bg {
    background: none;
  }

  p.cta-subtitle {
    color: #7F848D;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 600;
  }

  p.cta-footerText {
    margin-top: 24px;
    color: #7F848D;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
  }

  .footer-menu {
    text-align: center;
  }

  /* No Changes */
  /*

  h2.ui.header.hero-smallheader {
    margin-bottom: 36px;
    font-size: 14px;
    line-height: 15px;
    font-weight: 700;
    letter-spacing: 3.36px;
    text-transform: uppercase;
  }

  .hero-quote-bg {
    background: url('/media/graphics/AssetScalingLines-Both.png') no-repeat center center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  */
}

.collapsible-visible {
  position: absolute;
  top:0;
  z-index: 1; /* Stay on top */
  height: 60vh;
  right: 0;
  background-color: #fff; /* White */
  transition: right 0.3s ease-in-out;
  width: 60vw;
  overflow-y: scroll;
}


.ps_styled_btn {
  background-color: #922bd2 !important;
  color: #fff !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  border: 0.125rem #1d2130 !important;
  cursor: pointer !important;
  margin: 0 !important;
}

.ps_styled_btn:hover {
  background-color: #313852 !important;
  border-color: #313852 !important;
}


@media screen and (min-width:0px) and (max-width:768px) {
  .ps_styled_btn {
    font-size: 0.9rem !important;
  }
}

.loi_notes {
  color:#351c75;
  font-style: italic;
}

.pdf_contenteditable.placeholder::before {
  content: attr(data-placeholder);
  color: rgba(128, 128, 128, 0.5);
  border-bottom:1px solid black;
  line-height: inherit;
}


.pdf_contenteditable.error {
  border: 1px solid red;
  line-height: inherit;
}

.input_in_pdf {
  padding: 0.2em 0.9em !important;
}

.pdf-read-only-section {
  background-color: #fcfcc5;
  border-radius: 20px;
}

.pdf-read-only-section::after {
  content: '*This section will not be included in the downloadable version of the PDF';
  color:#351c75;
  font-style: italic;
  font-size: 18px;
}